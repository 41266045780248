<template>
  <div>
    <div class="cate-list">
      <div v-for="(item, index) in cateList" :key="index" @click="selectCate(item)" class="cate-item">
        <img :src="item.logo" class="cate-logo">
      </div>
    </div>
    <div class="cate-info" v-if="productBox.cateInfo.name.length > 0">
      {{ productBox.cateInfo.name }}
    </div>
    <div class="product-box" @scroll.passive="getScroll($event)">
      <template v-if="productBox.list.length > 0">
        <div class="product-item" v-for="(item, index) in productBox.list" :key="index" @click.stop="router('detail', { id: item.id } )">
          <div class="product-image">
            <img :src="item.head_img">
          </div>
          <div class="product-info">
            <div class="product-title">{{ item.name }}</div>
            <div class="product-other">
              <div class="product-author">{{ item.author }}</div>
              <div class="product-money">¥ {{ item.money }}</div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="data-null">
          无藏品数据
        </div>
      </template>
      <div class="product-loading" v-if="productBox.loading">
        <i class="el-icon-loading"></i>
        加载中...
      </div>
    </div>
    <Login v-show="vuex_login_member_show"></Login>
  </div>
</template>

<script>
import Login from "@/components/Login.vue"
export default {
  name: 'nft-list',
  components: {
    Login,
  },
  data() {
    return {
      cateList: [],
      productBox: {
        page: 1,
        total: 0, // 总条数
        limit: 0, // 总页数
        size: 12,
        loading: false,
        cateInfo: {
          id: '',
          name: ''
        }, // 当前选中的分类信息
        list: [],
      }
    }
  },
  mounted() {
    this.getCateList();
  },
  methods: {
    // 获取分类列表
    getCateList() {
      this.$api.nft_product.getCateList().then((res) => {
        this.cateList = res.data
        let cateId = parseInt(this.$route.query.cate_id)
        if (cateId > 0) {
          let info = res.data.find(item => item.id === cateId)
          if (info) {
            this.productBox.cateInfo = info
          }
        }
        else {
          this.productBox.cateInfo = res.data[0]
        }
        this.getProductList()
      });
    },
    // 获取所有藏品
    getProductList() {
      this.productBox.loading = true
      this.$api.nft_product.getList({
        page: this.productBox.page,
        size: this.productBox.size,
        cate_id: this.productBox.cateInfo.id,
      }).then((res) => {
        this.productBox.page = res.data.page
        this.productBox.total = res.data.total
        this.productBox.limit = res.data.limit
        if (res.data.page === 1) {
          this.productBox.list = res.data.list
        } else {
          this.productBox.list.push(...res.data.list)
        }
        this.productBox.loading = false
      });
    },
    // 选中分类
    selectCate(info) {
      this.productBox.page = 1
      this.productBox.cateInfo = info
      this.getProductList()
    },
    // 滚动到底部就加载下一页
    getScroll(event) {
      let scrollBottom =
          event.target.scrollHeight -
          event.target.scrollTop -
          event.target.clientHeight;
      if (this.productBox.loading === false && scrollBottom < 40 && this.productBox.page < this.productBox.limit) {
        this.productBox.page = this.productBox.page + 1
        this.getProductList()
      }
    },
    // 进藏品详情页
    router(url, query) {
      if (this.isLogin === false) {
        this.$ku.vuex('vuex_login_member_show', true)
      }
      else {
        this.$router.push({
          path: url,
          query: query
        });
      }
    },
  }
}
</script>

<style lang="less" scoped>
.cate-list {
  width:1200px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: scroll;
  .cate-item {
    flex-basis: 160px;
    flex-shrink: 0;
    width:160px;
    height:80px;
    position: relative;
    margin:0 20px;
    cursor: pointer;
    .cate-logo {
      position:  absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
    }
  }
}
.cate-info {
  color:#ffffff;
  background: url(../../assets/nft/cate-border.png) no-repeat;
  background-size: 100%;
  height:40px;
  width:550px;
  line-height: 40px;
  font-size:18px;
  margin: 50px auto;
}
.product-box {
  width:1260px;
  margin:auto;
  margin-bottom: 50px;
  display: inline-block;
  height:800px;
  overflow: auto;
  .product-item {
    width:400px;
    min-height:400px;
    margin: 10px;
    cursor: pointer;
    float:left;
    .product-image {
      background: url(../../assets/nft/product-border.png) no-repeat;
      background-size: 100% 100%;
      height:400px;
      width:400px;
      position: relative;
      margin-bottom:20px;
      img {
        position: absolute;
        height:392px;
        width:392px;
        left: 4px;
        top: 4px;
        right:4px;
        bottom:4px;
        border-radius: 18px;
      }
    }
    .product-info {
      margin-bottom: 30px;
      .product-title {
        color:#ffffff;
        font-size: 18px;
        font-weight: 700;
        text-align: left;
        margin-bottom: 10px;
      }
      .product-other {
        .product-author {
          color:#999999;
          font-size:16px;
          float: left;
        }
        .product-money {
          color:#FFA200;
          font-size:23px;
          font-weight: 700;
          float: right;
        }
      }
    }
  }
}
.data-null, .product-loading {
  color: #999999;
  padding: 30px 0;
}
</style>
