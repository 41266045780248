<template>
  <div class="login-mask" @click.stop="$ku.vuex('vuex_login_member_show', false)">
    <div class="member" @click.stop>
      <div class="checkbox">
        <div
            class="anniu"
            :class="memberType == 'login' ? 'checked' : ''"
            @click="memberType = 'login'"
        >
          登录
        </div>
        <div
            class="anniu"
            :class="memberType == 'register' ? 'checked' : ''"
            @click="memberType = 'register'"
        >
          注册
        </div>
      </div>
      <div class="RoleHeader-inputs" v-if="memberType == 'login'">
        <el-input v-model="form.mobile" placeholder="请输入您手机号"></el-input>
        <el-input
            v-model="form.password"
            placeholder="请输入您的密码"
            v-if="loginType == 'password'"
        ></el-input>
        <el-input
            v-model="form.captcha"
            placeholder="请输入验证码"
            v-if="loginType == 'code'"
        >
          <div slot="suffix" class="code" @click="getCode('mobilelogin')">
            <span class="code-span">{{ codeText }}</span>
          </div>
        </el-input>
        <div class="login-type" @click="loginTypeChange">
          {{ loginTypeText }}
        </div>
      </div>
      <div class="RoleHeader-inputs" v-if="memberType == 'register'">
        <el-input v-model="form.mobile" placeholder="请输入您手机号"></el-input>
        <el-input
            v-model="form.password"
            placeholder="请输入您的密码"
        ></el-input>
        <el-input
            v-model="form.confirmPassword"
            placeholder="再次输入您的密码"
        ></el-input>
        <el-input v-model="form.username" placeholder="请输入用户名"></el-input>
        <el-input v-model="form.code" placeholder="请输入验证码">
          <div slot="suffix" class="code" @click="getCode('register')">
            <span class="code-span">{{ codeText }}</span>
          </div>
        </el-input>
      </div>
      <div class="button">
        <img
            :src="
          memberType == 'login' ? require('../assets/images/login.png') : require('../assets/images/register.png')
        "
            alt=""
            @click="memberButtonClick"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {

  watch: {
    vuex_login_member_type(newValue) {
      if (newValue) {
        console.log('Login component is now visible.',newValue);
        this.memberType=newValue;
      }
    }
  },
  data() {
    return {
      memberType: 'login',
      form: {},
      codeText: "获取验证码",
      codeState: 0,
      timer: "",
      loginType: "password",
      loginTypeText: "验证码登录",
    };
  },
  methods: {
    loginTypeChange() {
      this.loginType == "password"
          ? ((this.loginType = "code"), (this.loginTypeText = "密码登录"))
          : ((this.loginType = "password"), (this.loginTypeText = "验证码登录"));
    },
    // 登录注册提交
    memberButtonClick() {
      this.form.userType = this.userType;
      if (this.loginType == "code") {
        this.$api.user.mobilelogin(this.form).then((res) => {
          this.$ku.vuex("isLogin", true);
          this.$ku.vuex("vuex_userinfo", res.data.userinfo);
          this.$ku.vuex("vuex_login_member_show", false);
          location.reload();
        });
      } else {
        this.$api.user[this.memberType](this.form).then((res) => {
          this.$ku.vuex("isLogin", true);
          this.$ku.vuex("vuex_userinfo", res.data.userinfo);
          this.$ku.vuex("vuex_login_member_show", false);
          location.reload();
        });
      }
    },
    // 获取验证码
    getCode(event) {
      if (this.codeState == 1) {
        return;
      }
      this.codeText = "验证码发送中";
      this.codeState = 1;
      let data = {
        mobile: this.form.mobile,
        event,
      };
      this.$api.sms
          .send(data)
          .then((res) => {
            let timerVal = 60;
            this.timer = setInterval(() => {
              if (timerVal <= 0) {
                clearInterval(this.timer);
                this.codeState = 0;
                this.codeText = "获取验证码";
                return;
              }
              timerVal--;
              this.codeText = res.msg + `，请${timerVal}秒后重试`;
            }, 1000);
          })
          .catch(() => {
            this.codeText = "获取验证码";
            this.codeState = 0;
          });
    },
    // 退出登录
    logout() {
      this.$api.user.logout().then(() => {
        this.$ku.vuex("isLogin", false);
        this.$ku.vuex("vuex_userinfo", {});
      });
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="less" scoped>
.login-mask {
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  height:100%;
  width:100%;
  background:rgba(0,0,0,0.8);
  overflow: hidden;
  z-index: 999;
}
.member {
  position: absolute;
  left: 50%;
  top: 15vw;
  transform: translateX(-50%);
  width: 20vw;
  background: url(../assets/images/member-bg.png) no-repeat;
  background-size: 100% 100%;
  padding: 4% 2%;
  z-index: 9999;
  .checkbox {
    display: flex;
    justify-content: center;
    font-size: 1.1vw;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #ffffff;
    .anniu {
      margin: 0 1vw;
      cursor: pointer;
    }
    .checked {
      color: #ffed34;
      border-bottom: 1px solid #ffed34;
    }
  }
  .RoleHeader-inputs {
    margin: 2vw 0;
    .code {
      font-size: 0.5vw;
      cursor: pointer;
      line-height: 2.5vw;
      .code-span {
        border: 1px solid #ffffff;
        border-radius: 12px;
        padding: 0.2vw 0.5vw;
      }
    }
    .login-type {
      color: #fff;
      font-size: 0.5vw;
      text-align: right;
      cursor: pointer;
    }
  }
  .button {
    width: 10vw;
    margin: 0 auto;
    margin-top: 1vw;
    cursor: pointer;
    img {
      width: 100%;
    }
  }
}
</style>
